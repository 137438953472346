import { HrefOption } from './type';
import { User } from '../store/accounts/type';

/*
 * Drawer Option
 */
export const DrawerDefaultOption: HrefOption[] = [
  {
    name: 'HOME',
    href: '/',
  },
  {
    name: 'MEMBERSHIP',
    href: '/membership',
    children: ['/membership/faq', '/membership/spaceoption'],
  },
  {
    name: 'BRANCH',
    href: '/branch/place/list',
  },
  // {
  //   name: 'COLIVING',
  //   href: 'https://colivingseoul.io',
  // },
  {
    name: '투어·입주문의',
    href: '/tour',
  },
  {
    name: '대관문의',
    href: '/rent',
  },
  // {
  //   name: "단기스테이",
  //   href: "/shortstay"
  // },
  // {
  //   name: "프로그램",
  //   href: "/program"
  // },
];

export const DrawerOptions: HrefOption[] = [
  ...DrawerDefaultOption,
  {
    name: '회원가입',
    href: '/auth/login_ask_to_join',
  },
  {
    name: '로그인',
    href: '/auth/login',
  },
];

export const DrawerAuthOption: HrefOption[] = [
  ...DrawerDefaultOption,
  {
    name: '마이페이지',
    href: '/mypage/profile',
  },
];

export const DrawerSideOptions: HrefOption[] = [
  {
    name: '회사소개',
    href: '/company',
  },
  {
    name: '개발문의',
    href: '/develop',
  },
  {
    name: '파트너십',
    href: '/partnership',
  },
  {
    name: '채용',
    href: 'https://localstitch.breezy.hr/',
  },
];

// PLACE
export const SubHeaderOptions: HrefOption[] = [
  {
    name: '지점',
    href: '/branch/place/list',
  },
  {
    name: '리테일',
    href: '/retail/list',
  },
  {
    name: '소식',
    href: '/branch/activity_list',
  },
  {
    name: '스티치 레터',
    href: '/stitchletter',
  },
];

// MEMBERSHIP Menu
export const MemberShipOptions: HrefOption[] = [
  {
    name: '멤버십소개',
    href: '/membership',
  },
  {
    name: '공간옵션',
    href: '/membership/spaceoption',
  },
  {
    name: 'FAQ',
    href: '/membership/faq',
  },
];

// PLUS Sub Header
export const SubHeaderAuthOptions: HrefOption[] = [
  {
    name: '커뮤니티',
    href: '/community/new_post',
  },
  {
    name: '내 지점',
    href: '/myplace/favorite',
  },
  {
    name: '멤버 가이드',
    href: 'https://localstitch2020.notion.site/Local-Stitch-USER-GUIDE-b2fdd40030504786ba61c1a8daba17a7',
  },
  {
    name: '멤버십 카드',
    href: '/mobile/membership_card',
  },
  {
    name: '마이페이지',
    href: '/mypage/profile',
  },
];

// PLUS > MyPage
export const MyPageOptions: HrefOption[] = [
  {
    name: '멤버십',
    href: '/mypage/membership',
  },
  {
    name: '예약',
    href: '/mypage/reserve',
  },
  {
    name: '결제',
    href: '/mypage/pay',
  },
  {
    name: '카드/계좌',
    href: '/mypage/set_info',
  },
  {
    name: '프로필',
    href: '/mypage/profile',
  },
];

// PLUS > MyPlace
export const MyPlaceOption: HrefOption[] = [
  {
    name: '자주찾는 지점',
    href: '/myplace/favorite',
  },
  {
    name: '가까운 공간',
    href: '/myplace/close_work_space',
  },
  {
    name: '가까운 회의실',
    href: '/myplace/close_meet_room',
  },
  {
    name: '가까운 리테일',
    href: '/myplace/close_retail',
  },
];

// PLUS > Membership Plus
export const MembershipPlusOption: HrefOption[] = [
  {
    name: '공간옵션',
    href: '/plus/space_option',
  },
  // {
  //   name: "대관 문의",
  //   href: "/plus/ask_rent"
  // },
  // {
  //   name: "단기 스테이",
  //   href: "/plus/short_stay"
  // },
  // {
  //   name: "프로그램",
  //   href: "#"
  // },
];

// Space Info
export const SpaceType = {
  SEAT: '1인 데스크',
  OFFICE: '오피스',
  LIVING_STUDIO: '1인 주거공간',
};

// Bank Code & Name
export const BANK_CODE = {
  WOORI: '020',
  KB: '004',
  IBK: '003',
  HANA: '081',
  SHINH: '088',
  NH: '011',
  SC: '023',
};

export const BANK_NAME = {
  '020': '우리은행',
  '004': '국민은행',
  '003': '기업은행',
  '081': '하나은행',
  '088': '신한은행',
  '011': '농협은행',
  '023': 'SC은행',
};

export const resignUser: User = {
  id: -1,
  username: '탈퇴회원',
  profile: {
    display_name: '탈퇴회원',
    profile_picture: null,
  },
};
