import moment from 'moment';
import { BANK_CODE, BANK_NAME } from '../constants'; // Cookie 문자열을 Object로 변한

export var cookieStringToObject = function cookieStringToObject(cookieString) {
  var cookies = {};

  if (cookieString) {
    //* "token=value"
    var itemString = cookieString === null || cookieString === void 0 ? void 0 : cookieString.split(/\s*;\s*/);
    itemString.forEach(function (pairs) {
      //* ["token","value"]
      var pair = pairs.split(/\s*=\s*/);
      cookies[pair[0]] = pair.splice(1).join('=');
    });
  }

  return cookies;
}; // API 에러 메시지

export var getAPIErrorMessage = function getAPIErrorMessage(response) {
  var key = Object.keys(response.data)[0];

  if (key === 'detail') {
    return response.data[key];
  }

  if (typeof response.data[key] === 'object') {
    var sub = Object.keys(response.data[key])[0];
    return response.data[key][sub];
  }

  return response.data[key][0];
}; // 빈 자리수 0으로 채워넣기
// example) 1 > 01
// example) 9 > 09

export var digitString = function digitString(n, digits) {
  var zero = '';
  var str = n.toString();

  if (str.length < digits) {
    for (var i = 0; i < digits - str.length; i++) {
      zero += '0';
    }
  }

  return zero + str;
}; // Branch ID로 Branch 이름 얻기

export var getBranchNameByID = function getBranchNameByID(branches, id) {
  return branches.filter(function (branch) {
    return branch.id === id;
  })[0].name;
}; // Axios Config
// Authorization 헤더 만들어주기

export var makeAuthorizationConfig = function makeAuthorizationConfig(token) {
  return {
    headers: {
      Authorization: "Bearer ".concat(token)
    }
  };
}; // Local Stitch Cookie Setting
// Access Token (1일) & Refresh Token (14일)

export var setLocalStitchCookie = function setLocalStitchCookie(setCookie, access, refresh) {
  var option = {
    path: '/',
    maxAge: 60 * 60 * 24
  };
  setCookie('access_token', access, option);
  option.maxAge = 60 * 60 * 24 * 14;
  setCookie('refresh_token', refresh, option);
};
export var getBankNameByCode = function getBankNameByCode(code) {
  return BANK_NAME[code];
};
export var getClassNameByCode = function getClassNameByCode(code) {
  return Object.keys(BANK_CODE).filter(function (key) {
    if (BANK_CODE[key] === code) return key;
  })[0].toLowerCase();
};
export var viewAccount = function viewAccount(account) {
  var first = account.slice(0, 4);
  var second = account.slice(4, 8);
  var middle = account.slice(8, 10);
  var end = account.slice(10, 14);
  var result = [first, second, middle, end];
  return result.join('-');
};
export var calculateTimeStamp = function calculateTimeStamp(time) {
  var today = moment().unix();
  var ts = moment(time).unix();
  var cal = today - ts;
  var min = cal / 60;
  var hour = min / 60;
  var day = hour / 24;
  var month = day / 30;
  var year = month / 12;
  if (min < 1) return '방금';
  if (min < 60) return "".concat(Math.floor(min).toString(), "\uBD84 \uC804");
  if (hour < 24) return "".concat(Math.floor(hour).toString(), "\uC2DC\uAC04 \uC804");
  if (day < 30) return "".concat(Math.floor(day).toString(), "\uC77C \uC804");
  if (month < 12) return "".concat(Math.floor(month).toString(), "\uB2EC \uC804");
  return "".concat(Math.floor(year).toString(), "\uB144 \uC804");
};